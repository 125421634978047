
import { FilterOperation } from '@/models/app/filter-operation.enum';
import { CourseRegistration } from '@/models/courses/filtered-course.interface';
import { Registration } from '@/models/registrations/registration.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import { User } from '@/models/users/user.class';
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import RegistrationItem from '../components/RegistrationItem.vue';
import { translateApi } from '../utils/translate-api';
import { createRouteWithLocaleParam } from '@/utils/create-route-with-locale-param';
import { UseCurrentLocaleMixin } from '@/mixins/use-current-locale.mixin';

const registrationModule = namespace('registration');
const authModule = namespace('auth');

@Component({
  components: { RegistrationItem },
  filters: {
    translateApi,
  },
})
export default class RegistrationData extends Mixins(UseCurrentLocaleMixin) {
  @registrationModule.Action('fetchRegistrations')
  fetchRegistrations!: (filter?: FetchAllParams) => Registration[];

  @registrationModule.Getter('registrations')
  registrations!: Registration[];

  @authModule.Getter('authenticatedUser')
  authenticatedUser!: User;

  get futureRegistrations() {
    const currentDate = new Date();

    const registrations = this.registrations.filter(registration => {
      if (
        registration &&
        registration.session &&
        typeof registration.session !== 'string' &&
        registration.session.startDate
      ) {
        const sessionDate = new Date(registration.session.startDate);
        if (sessionDate >= currentDate) {
          return registration;
        }
        return;
      }
    });

    const courseRegistrations: CourseRegistration[] = [];

    for (const registration of registrations) {
      const courseRegistration = this.createFilteredCourseRegistration(registration);
      if (courseRegistration) {
        courseRegistrations.push(courseRegistration);
      }
    }
    return courseRegistrations;
  }

  get pastRegistrations() {
    const currentDate = new Date();
    const registrations = this.registrations.filter(registration => {
      if (
        registration &&
        registration.session &&
        typeof registration.session !== 'string' &&
        registration.session.startDate
      ) {
        const sessionDate = new Date(registration.session.startDate);
        if (sessionDate < currentDate) {
          return registration;
        }
      }
    });
    const courseRegistrations: CourseRegistration[] = [];
    for (const registration of registrations) {
      const courseRegistration = this.createFilteredCourseRegistration(registration);
      if (courseRegistration) {
        courseRegistrations.push(courseRegistration);
      }
    }
    return courseRegistrations;
  }

  createFilteredCourseRegistration(registration: Registration) {
    if (
      registration.session &&
      typeof registration.session !== 'string' &&
      registration.students.length > 0
    ) {
      let locationString = '';
      const { location, isOnline } = registration.session;
      if (isOnline) {
        locationString = `${this.$t('course.onlineLocation')}`;
      }
      if (location) {
        locationString = `${translateApi(location.name, this.$i18n.locale)}, <br/>${translateApi(
          location.address,
          this.$i18n.locale,
        )}, <br />
        ${location.zipCode} ${translateApi(location.city, this.$i18n.locale)}`;
      }

      const courseRegistration: CourseRegistration = {
        title: translateApi(registration.session.course.title, this.$i18n.locale),
        price: registration.session.price,
        session: registration.session,
        paymentStatus: registration.paymentStatus,
        startDate: registration.session.startDate,
        locationString: locationString,
        language: translateApi(registration.session.language.name, this.$i18n.locale),
        slug: translateApi(registration.session.course.slug, this.$i18n.locale),
        students: registration.students,
      };
      return courseRegistration;
    }
  }

  openCourseDetail(slug: string) {
    const route = createRouteWithLocaleParam('course', this.localeCode, {
      slug: slug,
    });
    this.$router.push(route);
  }

  async created() {
    const filter: FetchAllParams = {
      filters: {
        registeringUser: { value: this.authenticatedUser._id, operation: FilterOperation.Equals },
      },
    };
    await this.fetchRegistrations(filter);
    // this.orderRegistrationsInTime();
  }
}
