
import { Component, Vue, Prop } from 'vue-property-decorator';
import { translateApi } from '../utils/translate-api';
import moment from 'moment';
import { CourseRegistration } from '../models/courses/filtered-course.interface';
import { TranslatedProperty } from '@/models/shared/translated-property.class';
import { PaymentStatus } from '@/enums/payment-status.enum';
import { TranslateResult } from 'vue-i18n';
import { Student } from '@/models/students/student.class';

@Component({
  components: {},
  filters: {
    translateApi,
  },
})
export default class RegistrationItem extends Vue {
  badgeColor = '';

  @Prop({ type: Object })
  courseRegistration!: CourseRegistration;

  @Prop({ type: Array })
  students!: Student[];

  @Prop({ type: Boolean })
  past!: boolean;

  formattedDate(date: string) {
    moment.locale('nl-be');
    if (!date) {
      return '';
    }
    return moment(date).format('L');
  }

  get paymentStatus() {
    let status: TranslateResult = '';
    switch (this.courseRegistration.paymentStatus) {
      case PaymentStatus.PAID:
        status = this.$t('account.registrations.payed');
        this.badgeColor = 'soft-success';
        break;
      default:
        status = '';
        this.badgeColor = '';
        break;
    }
    return status;
  }

  openCourseDetail() {
    this.$emit('click', this.courseRegistration.slug);
  }

  checkLength(shortDescription: TranslatedProperty) {
    const description = translateApi(shortDescription, this.$i18n.locale);
    if (description.length > 100) {
      return 'fade';
    }
    return '';
  }
}
